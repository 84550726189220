@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('Inter Light'), local('Inter-Light'),
  url("../fonts/inter-light/inter-light.woff2") format("woff2"),
  url("../fonts/inter-light/inter-light.woff") format("woff"),
  url("../fonts/inter-light/inter-light.ttf") format("ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Inter Regular'), local('Inter-Regular'),
  url("../fonts/inter-regular/inter-regular.woff2") format("woff2"),
  url("../fonts/inter-regular/inter-regular.woff") format("woff"),
  url("../fonts/inter-regular/inter-regular.ttf") format("ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Inter Medium'), local('Inter-Medium'),
  url("../fonts/inter-medium/inter-medium.woff2") format("woff2"),
  url("../fonts/inter-medium/inter-medium.woff") format("woff"),
  url("../fonts/inter-medium/inter-medium.ttf") format("ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
  url("../fonts/inter-semibold/inter-semibold.woff2") format("woff2"),
  url("../fonts/inter-semibold/inter-semibold.woff") format("woff"),
  url("../fonts/inter-semibold/inter-semibold.ttf") format("ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('Inter Bold'), local('Inter-Bold'),
  url("../fonts/inter-bold/inter-bold.woff2") format("woff2"),
  url("../fonts/inter-bold/inter-bold.woff") format("woff"),
  url("../fonts/inter-bold/inter-bold.ttf") format("ttf");
}

@font-face {
  font-family: "Tilda Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('TildaSans Regular'), local('TildaSans-Regular'),
  url("../fonts/tildasans-regular/tildasans-regular.woff2") format("woff2"),
  url("../fonts/tildasans-regular/tildasans-regular.woff") format("woff"),
  url("../fonts/tildasans-regular/tildasans-regular.ttf") format("ttf");
}

@font-face {
  font-family: "Tilda Sans";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('TildaSans Medium'), local('TildaSans-Medium'),
  url("../fonts/tildasans-medium/tildasans-medium.woff2") format("woff2"),
  url("../fonts/tildasans-medium/tildasans-medium.woff") format("woff"),
  url("../fonts/tildasans-medium/tildasans-medium.ttf") format("ttf");
}

@font-face {
  font-family: "Tilda Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('TildaSans SemiBold'), local('TildaSans-SemiBold'),
  url("../fonts/tildasans-semibold/tildasans-semibold.woff2") format("woff2"),
  url("../fonts/tildasans-semibold/tildasans-semibold.woff") format("woff"),
  url("../fonts/tildasans-semibold/tildasans-semibold.ttf") format("ttf");
}

@font-face {
  font-family: "Tilda Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('TildaSans Bold'), local('TildaSans-Bold'),
  url("../fonts/tildasans-bold/tildasans-bold.woff2") format("woff2"),
  url("../fonts/tildasans-bold/tildasans-bold.woff") format("woff"),
  url("../fonts/tildasans-bold/tildasans-bold.ttf") format("ttf");
}

@font-face {
  font-family: "Tilda Sans";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: local('TildaSans ExtraBold'), local('TildaSans-ExtraBold'),
  url("../fonts/tildasans-extrabold/tildasans-extrabold.woff2") format("woff2"),
  url("../fonts/tildasans-extrabold/tildasans-extrabold.woff") format("woff"),
  url("../fonts/tildasans-extrabold/tildasans-extrabold.ttf") format("ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url("../fonts/montserrat-medium/montserrat-medium.woff2") format("woff2"),
  url("../fonts/montserrat-medium/montserrat-medium.woff") format("woff"),
  url("../fonts/montserrat-medium/montserrat-medium.ttf") format("ttf");
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  padding: 0;
}

:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}

ul li {
  list-style-type: none
}

:root {
  /* применение var(--color-blue); */
  /*css - переменные*/
  --grey-opacity: 15, 3, 37;
  --dark-opacity-2: 19, 22, 27;
  --blue-opacity: 8, 90, 172;
  --dark-opacity: 49, 52, 79;
  --dark-blue: #002033;
  --white: #ffffff;
  --white-opacity: 215, 245, 250;
  --white-2: #fbfafb;
  --black: #000000;
  --black-2: #050404;
  --black-opacity: 0, 0, 0;
  --blue: #0B4C89;
  --blue-2: #356CA6;
  --blue-2-opacity: 53, 108, 166;
  --blue-3: #2D91F5;
  --blue-4: #0078D2;
  --blue-5: #085AAC;
  --orange: #F0700D;
  --yellow: #F2C94C;
  --purple: #8B11E7;
  --lilac: #6C588D;
  --red: #F75237;
  --red-2: #CC2525;
  --red-2-opacity: 204, 37, 37;
  --grey: #BDBDBD;
  --light-gray: #F9F9F9;
  --light-gray-1: #F5F5F5;
  --dark-gray: #414141;
  --dark-gray-opacity: 65, 65, 65;
  --green: #27AE60;
  --skyblue: #38CEE7;
  --skyblue-2: #2DB4DA;
  --skyblue-3: #CDF3F9;
  --silver: #A6B1B8;
  --silver-opacity: 53, 108, 166;
  --silver-2: #C8F7FF;
  --aqua: #D7F5FA;
  --aqua-2: #DBEDFF;
  --aqua-3: #DEEBFF;
  --grey-2: #828181;
  --green-2: #11A863;
  --beige: #fbd5b7;
}

.visually-hidden:not(:focus):not(active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--white);
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
}

svg {
  flex-shrink: 0;
}

html.fixed-safari {
  height: calc(100vh - 80px);
  overflow: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html.fixed-safari::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

body.fixed-safari {
  height: 100vh;
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body.fixed-safari::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

body.fixed {
  overflow: hidden;
}

button {
  border-radius: 0;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}

.hidden {
  display: none !important;
  transition: 0.3s;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.body-container {
  max-width: 1920px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  margin: 0 auto;
  position: relative;
  font-family: "Tilda Sans", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-2);
}

.main__container {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 1300px;
  margin: 0 auto;
  height: 100%;
}

.main {
  position: relative;
  padding: 40px 45px 200px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-image: url("/public/images/background-piter.png");
  flex-grow: 1;
  margin: 70px 0 0;
}

.main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  max-width: 210px;
  min-height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  font-weight: 500;
  color: var(--white);
  border: none;
  outline: none;
  appearance: none;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}

.button__icon {
  fill: var(--white);
}

.button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.header {
  background-color: var(--blue);
  position: fixed;
  width: 100%;
  z-index: 5;
  max-width: 1620px;
}

.header__container {
  max-width: 1390px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 15px 45px;
  margin: 0 160px 0 auto;
}

.header__content {
  display: flex;
  gap: 40px;
  align-items: center;
}

.profile {
  display: flex;
  width: 100%;
  justify-content: center;
}

.profile__button {
  background-color: var(--blue-3);
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  transition: 0.3s;
  text-transform: uppercase;
}

.profile__button:hover {
  background-color: var(--skyblue);
  transition: 0.3s;
}

.profile__photo {
  display: flex;
  border-radius: 100%;
  overflow: hidden;
}

.header__profile {
  border-radius: 100%;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.show {
  display: none;
}

.burger {
  display: none;
}

.search-mobile {
  display: none;
}

.accessible {
  display: flex;
}

.logo-project--big .logo-project__img {
  width: 110px;
  height: 110px;
}

.tags {
  display: flex;
  row-gap: 5px;
  column-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.tags__link {
  display: flex;
}

.tags__link:hover .tags__text {
  color: var(--skyblue);
  transition: 0.3s;
}

.tags__text {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  transition: 0.3s;
  color: var(--white);
}

.tags__blue {
  color: var(--skyblue);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  transition: 0.3s;
}

.tags__dropdown {
  position: relative;
}

.tags__toggle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--white);
  font-family: "Tilda Sans", "Arial", sans-serif;
  text-transform: uppercase;
  display: flex;
  gap: 5px;
  align-items: center;
}

.tags__icon {
  fill: var(--skyblue);
  transform: rotate(-90deg);
  transition: 0.3s;
}

.tags__icon--active {
  transform: rotate(90deg);
  transition: 0.3s;
}

.tags__menu {
  position: absolute;
  top: 30px;
  background-color: var(--white);
  padding: 5px 0;
  border: 1px solid var(--blue-2);
  border-radius: 4px;
  width: 205px;
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  left: 0;
}

.tags__menu .tags__item {
  padding: 0 12px;
  transition: 0.3s;
}

.tags__menu .tags__item:hover {
  background-color: var(--aqua-3);
  transition: 0.3s;
}

.tags__menu .tags__text {
  color: var(--black-2);
  padding: 8px 0;
  font-weight: 400;
  font-family: "Inter", "Arial", sans-serif;
}

.tags__item.active .tags__link .tags__text {
  color: var(--skyblue);
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.search:after {
  content: "";
  height: 1px;
  width: 200px;
  background-color: var(--white);
}

.search__container {
  display: flex;
  gap: 10px;
}

.search__input {
  max-width: 163px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;
  box-sizing: border-box;
  font-family: "Inter", "Arial", sans-serif;
  color: var(--silver);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.search__button {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.social {
  display: flex;
  gap: 5px;
  align-items: center;
}

.social--blue .social__link {
  fill: var(--blue-2);
  transition: 0.3s;
}

.social__link {
  fill: var(--black);
  display: flex;
  position: relative;
}

.social__link:hover {
  transition: 0.3s;
  fill: var(--blue-3);
}

.navigation {
  background-color: var(--blue-2);
  box-shadow: 0 4px 4px 0 rgba(var(--black-opacity) 0.25);
  padding: 30px;
  min-width: 300px;
}

.navigation__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: fixed;
  width: 100%;
  max-width: 240px;
}

.navigation__list {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.navigation__link {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navigation__icon {
  z-index: 1;
}

.navigation__border {
  display: flex;
}

.navigation__text {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.navigation__item--active .navigation__border {
  width: 35px;
  height: 35px;
  background-color: var(--skyblue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5px;
}

.navigation__link:hover .navigation__border {
  width: 35px;
  height: 35px;
  background-color: var(--skyblue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5px;
}

.navigation.open {
  transition-duration: 0.2s, 0.2s;
  transition-delay: 0.2s, 0s;
}

.navigation.open {
  transition-delay: 0s, 0.2s;
}

.promo {
  display: flex;
  gap: 45px;
  align-items: center;
  max-width: 100%;
  position: relative;
}

.promo__background {
  position: absolute;
  left: 0;
  top: 187px;
  z-index: -1;
}

.promo__container {
  max-width: 470px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.promo__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.promo__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
}

.promo__date {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
}

.promo__subtitle {
  font-family: "Inter", "Arial", sans-serif;
}

.hashlink {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.hashlink__link {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--black-2);
}

.hashlink__blue {
  color: var(--blue-2);
}

.promo__button {
  margin: 25px 0 0;
  gap: 5px;
}

.promo__wrap {
  position: relative;
  width: 100%;
}

.page__img {
  width: 100%;
  height: auto;
  display: flex;
}

.info {
  display: flex;
  max-width: 100%;
}

.info__item {
  position: relative;
  justify-content: end;
  display: flex;
  flex-direction: column;
}

.info__item:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(var(--grey-opacity), 0.00) 28.3%, rgba(var(--dark-opacity-2), 0.36) 52.8%, rgba(var(--dark-opacity-2), 0.80) 100%);
}

.info__item:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(var(--blue-opacity), 0.40) 0%, rgba(var(--blue-opacity), 0.40) 100%);
}

.info__image {
  width: 100%;
  height: auto;
}

.info__context {
  color: var(--white);
  font-weight: 700;
  line-height: 20px;
  z-index: 1;
  position: absolute;
  padding: 25px 15px 25px 30px;
}

.promo__line {
  height: 100px;
  background-color: var(--blue-2);
  margin: -40px 0 0;
  display: flex;
  align-items: center;
  padding: 10px;
}

.slider {
  position: relative;
  margin: 0 0 95px;
}

.helper-info {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: -40px;
  max-width: 775px;
}

.helper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  position: relative;
  flex-shrink: 0;
}

.helper__arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.helper__link {
  display: flex;
  align-items: center;
  height: 100%;
}

.helper__link--right {
  transform: rotate(180deg);
}

.helper__arrow {
  fill: var(--white);
}

.helper__numbers {
  color: #FFF;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
}

.helper__big {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
}

.helper__line {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

.helper__small {
  font-size: 30px;
  font-weight: 300;
  line-height: 34px;
}

.btn {
  margin: 25px 0 0;
  gap: 5px;
}

.banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.banner__img {
  height: auto;
  width: 100%;
}

.main__wrap {
  display: grid;
  grid-template-columns: 73% 24%;
  gap: 40px;
  max-width: 100%;
  height: 100%;
}

.new {
  display: grid;
  gap: 25px;
  grid-template-columns: 1fr 46%;
}

.new__item {
  height: 100%;
}

.new__item:nth-child(1) {
  grid-column: 1;
  grid-row: 3/-2;
}

.new__ref {
  display: flex;
  gap: 10px;
  color: var(--black-2);
}

.new__item:nth-child(1) .new__ref {
  flex-direction: column;
  gap: 15px;
}

.new__item:nth-child(1) .new__img {
  width: 100%;
  max-width: 100%;
}

.new__img {
  max-width: 190px;
  height: auto;
}

.new__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.new__item:nth-child(1) .new__container {
  max-width: none;
  gap: 15px;
}

.new__date {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.new__item:nth-child(1) .new__date {
  font-size: 16px;
  line-height: 20px;
}

.new__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  display: -webkit-box;
}

.new__item:nth-child(1) .new__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.new__subtitle {
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
}

.new__item:nth-child(1) .new__subtitle {
  display: -webkit-box;
}

.hashtags {
  display: flex;
  gap: 10px;
}

.hashtags__texts {
  color: var(--black-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
}

.new__item:nth-child(1) .hashtags__texts {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.hashtags__multicolored {
  color: var(--blue-2);
}

.button-link {
  display: flex;
  gap: 10px;
  background-color: transparent;
  margin: 0 0 0 auto;
}

.button-link__text {
  color: var(--blue-2);
  font-weight: 700;
  text-transform: uppercase;
}

.button-link__icon {
  fill: var(--blue-2);
}

.content {
  display: grid;
  gap: 30px;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
}

.content__wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.content__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 25px;
}

.content__link {
  display: flex;
  flex-direction: column;
  color: var(--black-2);
}

.content__images {
  border-radius: 0 0 0 3px;
  background-color: var(--skyblue-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 10px;
  gap: 2px;
}

.content__icon {
  border-radius: 3px 0 0 0;
  background-color: var(--skyblue-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  gap: 2px;
}

.content__link:hover .content__images {
  background-color: var(--blue-2);
}

.content__link:hover .content__icon {
  background-color: var(--blue-2);
}

.content__number {
  color: var(--white);
  text-align: center;
  font-size: 8px;
  font-weight: 800;
  line-height: 10px;
}

.content__imgs {
  position: relative;
  display: flex;
}

.content__img {
  width: 100%;
}

.icon__play {
  display: flex;
}

.icon-img {
  top: 0;
  right: 0;
  border-radius: 0 0 0 3px;
  flex-direction: column;
  gap: 2px;
  padding: 5px;
  transition: 0.3s;
}

.icon {
  position: absolute;
  background-color: var(--skyblue-2);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  justify-content: center;
  right: 0;
  bottom: 0;
}

.icon__number {
  color: var(--white);
  text-align: center;
  font-size: 8px;
  font-weight: 800;
  line-height: 10px;
}

.content__date {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 0 0;
  transition: 0.3s;
}

.content__title {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  align-self: stretch;
}

.content__texts {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.content__link:hover .icon {
  background-color: var(--blue-2);
  transition: 0.3s;
}

.content__link:hover .icon-img {
  background-color: var(--blue-2);
  transition: 0.3s;
}

.content__link:hover .content__date {
  background-color: var(--blue-2);
  color: var(--white);
  font-weight: 600;
  padding: 10px 0 0 10px;
  transition: 0.3s;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 1;
  width: 100%;
}

.partners {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
}

.partners--four {
  grid-template-columns: repeat(4, 1fr);
}

.partners__link {
  display: flex;
}

.partners__img {
  width: 100%;
}

.footer {
  padding: 35px 45px 20px;
  background-color: var(--blue);
  display: flex;
  gap: 35px;
  flex-direction: column;
  position: relative;
}

.footer__container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}

.footer__nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

.footer__navigation {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.footer__block {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
}

.footer__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 5px;
}

.footer__text {
  color: var(--white);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.footer__connect {
  font-weight: 600;
}

.footer__social {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 240px;
}

.footer__except {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.footer__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__content {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0 0;
}

.footer__wrap:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--blue-2);
  position: absolute;
}

.footer__copyright {
  color: var(--white);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  max-width: 190px;
}

.footer__links {
  display: flex;
  gap: 50px;
  align-items: center;
}

.footer__link {
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
}

.footer__partner {
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.aside__title {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
}

.drops {
  display: flex;
  background-color: var(--blue-2);
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  padding: 0 10px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}

.drops__select {
  display: flex;
  width: 100%;
  background-color: var(--blue-2);
  color: var(--white);
  border: none;
  justify-content: space-between;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px 0;
}

.drops:after {
  content: "";
  background-image: url("/public/images/arrow-dropdown.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.aside {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.aside_table {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ballot {
  display: flex;
  padding: 25px 20px;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  background: linear-gradient(0deg, rgba(var(--blue-opacity), 0.80) 0%, rgba(var(--blue-opacity), 0.80) 100%), url("/public/images/ballot-background.png"), lightgray -2.296px 0/ 222.815% 100% no-repeat;
  background-position: left;
}

.ballot__text {
  color: var(--white);
  text-align: center;
  text-shadow: 0 4px 4px rgba(var(--black-opacity) 0.25);
  font-weight: 700;
  line-height: 20px;
}

.ballot__form {
  display: flex;
  flex-direction: column;
  gap: 45px;
  max-width: 260px;
  width: 100%;
}

.ballot__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ballot__checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 3px;
  background-color: var(--white);
  justify-content: space-between;
  width: 100%;
  transition: 0.3s;
}

.ballot__checkbox--active {
  background-color: var(--silver-2);
}

.ballot__form--option .ballot__checkbox:hover {
  background-color: var(--silver-2);
  transition: 0.3s;
}

.ballot__label {
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.ballot__input {
  display: none;
}

.ballot__check {
  width: 10px;
  height: 10px;
  padding: 5px;
  background-color: var(--skyblue);
  display: none;
  border-radius: 100%;
}

.ballot__indicator {
  width: 15px;
  height: 15px;
  border: 1px solid var(--skyblue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  flex-shrink: 0;
}

.ballot__input:checked + .ballot__indicator .ballot__check {
  display: block;
}

.ballot__button {
  width: 100%;
  max-width: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  background-color: var(--skyblue);
  transition: 0.3s;
}

.ballot__button:hover {
  background-color: var(--blue-3);
  transition: 0.3s;
}

.ballot__number {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.ballot__container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ballot__subtitle {
  color: var(--white);
  text-align: center;
  text-shadow: 0 4px 4px rgba(var(--black-opacity) 0.25);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.spreading {
  display: flex;
  padding: 25px 20px;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  background-color: var(--blue-2);
  position: relative;
}

.spreading__img {
  position: absolute;
  opacity: 0.1;
  display: flex;
  right: 12px;
  top: 12px;
  z-index: 1;
}

.spreading__text {
  color: var(--white);
  text-align: center;
  text-shadow: 0 4px 4px rgba(var(--black-opacity) 0.25);
  font-weight: 700;
  z-index: 2;
}

.spreading__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  z-index: 2;
  width: 100%;
}

.spreading__input {
  display: flex;
  width: 100%;
  max-width: 260px;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: none;
}

.spreading__input::placeholder {
  color: var(--silver);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.spreading__button {
  border-radius: 4px;
  background-color: var(--skyblue);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  width: 100%;
  transition: 0.3s;
}

.spreading__button:hover {
  background-color: var(--blue-3);
  transition: 0.3s;
}

.spreading__group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: center;
}

.spreading__help,
.ballot__help,
.searching-wrapper__help {
  color: var(--red);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.table-scroll {
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.table-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.table-border {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--aqua);
}

.table {
  color: var(--black-2);
  border-collapse: collapse;
  width: 100%;
}

.table__th {
  text-align: center;
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  height: 30px;
  background-color: var(--black-2);
  padding: 7px 10px;
}

.table__row {
  height: 35px;
}

.table__number {
  width: 30px;
  max-width: 30px;
}

.table__cell {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  height: 35px;
  padding: 10px;
}

.table__orange {
  color: var(--orange);
}

.table__tbody .table__row:nth-child(2n) {
  background-color: var(--aqua);
}

.table__130 {
  width: 130px;
}

.table__right {
  text-align: start;
}

.table-scroll .table__cell,
.table-scroll .table__info {
  width: max-content;
}

.table__300 {
  width: 300px;
}

.nav {
  display: none;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 55px;
  left: 0;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100vh - 70px);
}

.nav::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.nav__list {
  display: grid;
  gap: 10px;
  padding: 30px 20px;
  justify-content: center;
  background-color: white;
}

.nav__link {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: start;
  color: var(--black-2);
}

.nav__text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.nav__footer {
  display: flex;
  padding: 30px 20px;
  background-color: var(--blue-2);
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.nav__block {
  display: flex;
  gap: 20px;
}

.tags--wrap {
  column-gap: 10px;
  row-gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.burger.open + .nav {
  display: flex;
}

.crumbs {
  display: flex;
  gap: 10px;
}

.crumbs__item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.crumbs__item:not(:first-child):before {
  content: "";
  display: block;
  background-color: var(--blue-2);
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.crumbs__link {
  color: var(--grey-2);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.crumbs__item:last-child .crumbs__link {
  color: var(--black-2);
}

.about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.buttons__item {
  padding: 10px;
  border-left: 1px solid var(--blue-2);
}

.buttons__link {
  color: var(--black-2);
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.buttons__item--active {
  padding: 10px 20px;
  border-radius: 3px;
  background-color: var(--blue-2);
  color: var(--white);
}

.buttons__item--active .buttons__link {
  color: var(--white);
}

.about__item {
  display: flex;
  gap: 15px;
  max-width: 415px;
}

.about__img {
  height: auto;
}

.about__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
}

.about__name {
  font-size: 20px;
  font-weight: 600;
}

.about__post {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about__post:before {
  content: "";
  display: block;
  max-width: 205px;
  width: 100%;
  height: 2px;
  background-color: var(--blue-2);
  margin: 0 0 0 -15px;
  z-index: -1;
  position: relative;
}

.about__post--hide {
  display: none;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.gap--60 {
  gap: 60px;
}

.gap--50 {
  gap: 50px;
}

.gap--40 {
  gap: 40px;
}

.gap--35 {
  gap: 35px;
}

.gap--30 {
  gap: 30px;
}

.gap--25 {
  gap: 25px;
}

.gap--20 {
  gap: 20px;
}

.map {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.map__container {
  display: flex;
  width: 100%;
  height: 485px;
  margin: 0 auto;
}

.map__img {
  width: 100%;
  height: auto;
}

.contacts {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
}

.contacts__title {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 395px;
  flex-shrink: 3;
}

.contacts__title:before {
  content: "";
  display: block;
  width: 3px;
  height: 70px;
  background-color: var(--blue-2);
}

.contacts__content {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-shrink: 0;
}

.contacts__link {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--blue-2);
  font-size: 20px;
  font-weight: 600;
}

.map__black {
  color: var(--black-2);
  font-family: "Inter", "Arial", sans-serif;
  font-weight: 400;
}

.subtitle {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
}

.requisite {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.requisite__item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  position: relative;
  gap: 20px;
}

.requisite__item:after {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--blue-2);
  bottom: 0;
  position: absolute;
}

.requisite__indication {
  font-size: 20px;
  font-weight: 500;
}

.requisite__name {
  text-align: right;
  font-family: "Inter", "Arial", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.choice {
  display: flex;
  gap: 20px;
  align-items: center;
}

.choice__buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.sorts {
  width: 100%;
}

.sorts__label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: var(--blue-2);
  min-height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  text-transform: uppercase;
  transition: 0.3s;
  color: var(--white);
  cursor: pointer;
}

.sorts:hover {
  background-color: var(--blue-3);
  transition: 0.3s;
}

.sorts__input {
  position: absolute;
  opacity: 0;
}

.sorts__input:checked + .sorts__label {
  background-color: var(--skyblue);
  transition: 0.3s;
}

.reset {
  background-color: var(--blue-2);
  padding: 8px 10px;
  transition: 0.3s;
  max-width: 100%;
  width: 100%;
}

.reset:hover {
  transition: 0.3s;
  background-color: var(--blue-3);
}

.date__input {
  border-radius: 4px;
  padding: 10px 40px 10px 10px;
  border: 1px solid var(--silver);
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", "Arial", sans-serif;
}

.date__input::placeholder {
  color: var(--silver);
}

.date {
  position: relative;
  display: flex;
  width: 100%;
}

.date__icon {
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  pointer-events: none;
}

.news {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.news__ref {
  display: flex;
  gap: 15px;
  color: var(--black-2);
}

.news__ref:hover .news__date {
  background-color: var(--blue-2);
  color: var(--white);
  margin: 0 0 0 -15px;
  transition: 0.3s;
  padding: 0 20px 0 15px;
}

.news__img {
  height: auto;
  display: flex;
  flex-shrink: 0;
  max-width: 300px;
}

.news__content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.news__date {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.3s;
}

.news__texts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.news__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}

.news__subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.hashtags {
  display: flex;
  gap: 10px;
  margin: auto 0 0;
  flex-wrap: wrap;
}

.hashtags__text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--black-2);
}

.hashtag__multicolored {
  color: var(--blue-2);
}

.hashtag__link:hover .hashtag__multicolored {
  color: var(--orange);
}

.pagination {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.pagination__arrows {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pagination__arrows--right .pagination__icon {
  transform: rotate(180deg);
}

.pagination__arrows--right .pagination__link:nth-child(1) {
  order: 1;
}

.pagination__arrows--disabled .pagination__icon {
  stroke: var(--grey);
}

.pagination__arrows--disabled .pagination__link {
  cursor: auto;
}

.pagination__link {
  color: var(--black-2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination__icon {
  display: flex;
  fill: var(--blue-2);
}

.pagination__icon:hover {
  fill: var(--blue-3);
}

.pagination__list {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.pagination__item--active .pagination__number {
  color: var(--white);
  background-color: var(--blue-2);
}

.pagination__number {
  font-family: "Inter", "Arial", sans-serif;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 0;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.3s;
  border-radius: 4px;
}

.pagination__number:hover {
  color: var(--white);
  background-color: var(--blue-3);
  transition: 0.3s;
}

.pagination__dots {
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  line-height: 18px;
  padding: 12px 0;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.3s;
  border-radius: 4px;
}

.pagination__dots:hover {
  color: var(--white);
  transition: 0.3s;
  background-color: var(--blue-3);
  line-height: 20px;
}

.pagination__item--active .pagination__dots {
  color: var(--white);
  background-color: var(--blue-2);
  line-height: 20px;
}

.aside__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.aside__item {
  display: flex;
  gap: 20px;
  align-items: center;
}

.aside__item:before {
  content: "";
  display: flex;
  width: 3px;
  flex-shrink: 0;
  height: 100%;
  min-height: 75px;
  background-color: var(--blue-2);
}

.aside__link {
  display: flex;
  flex-direction: column;
  color: var(--black-2);
  gap: 5px;
  align-items: flex-start;
}

.aside__link:hover .aside__date {
  background-color: var(--blue-2);
  color: var(--white);
  margin: 0 0 0 -20px;
  transition: 0.3s;
  padding: 0 20px;
}

.aside__date {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  transition: 0.3s;
  line-height: 16px;
}

.aside__text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.news__block {
  display: flex;
  position: relative;
}

.sort {
  display: flex;
  position: absolute;
  gap: 5px;
  top: 10px;
  left: 10px;
}

.sort__item {
  border-radius: 3px;
  background: var(--blue-2);
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
}

.sort__icon {
  display: flex;
  flex-shrink: 0;
}

.sort__text {
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: lowercase;
}

.header-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

.data {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.data__text {
  font-size: 16px;
  font-family: "Inter", "Arial", sans-serif;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.data__text:not(:first-child) {
  gap: 10px;
}

.data__text:not(:first-child):before {
  content: "";
  width: 2px;
  height: 100%;
  display: block;
  background-color: var(--blue-2);
  flex-shrink: 0;
}

.data__blue {
  color: var(--blue-2);
}

.icon-video__play {
  width: 80px;
  height: 80px;
  background-color: var(--skyblue-2);
  padding: 10px;
  border-radius: 5px;
}

.video:hover .icon-video__play {
  background-color: var(--blue-2);
}

.icon-video {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.hashlink {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.hashlink__link {
  color: var(--black-2);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.hashlink__multicolored {
  color: var(--blue-2);
}

.source {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  line-height: 30px;
}

.source__text {
  font-weight: 400;
}

.documents__icon {
  fill: var(--white);
  stroke: var(--blue-2);
  display: flex;
}

.documents__arrow {
  display: flex;
  fill: var(--blue-2);
}

.documents__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
}

.documents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.documents__item {
  display: flex;
  gap: 15px;
}

.documents__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.documents__subtitle {
  font-size: 20px;
  font-weight: 500;
}

.documents__link {
  display: flex;
  gap: 10px;
  align-items: center;
}

.documents__text {
  color: var(--blue-2);
  font-weight: 700;
  text-transform: uppercase;
}

.photos {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: normal;
}

.photos__img {
  width: 100%;
  height: auto;
}

.photos__link {
  display: flex;
}

.arrows--photo .arrows__right {
  top: calc(50% - 25px);
}

.arrows--photo .arrows__left {
  top: calc(50% - 25px);
}

.arrows__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--blue-2);
  border-radius: 4px;
  margin: 0 0 0 -20px;
  fill: var(--white);
  position: absolute;
  left: 0;
  top: calc(50% - 24px);
  cursor: pointer;
}

.arrows__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--blue-2);
  border-radius: 4px;
  fill: var(--white);
  transform: rotate(180deg);
  margin: 0 -20px 0 0;
  position: absolute;
  right: 0;
  top: calc(50% - 24px);
  cursor: pointer;
}

.arrows--video .arrows__right,
.arrows--video .arrows__left {
  top: calc(50% - 60px);
}

.arrows__icon {
  display: flex;
  flex-shrink: 0;
  fill: var(--white);
}

.pointer {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.pointer__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--blue-2);
  border-radius: 4px;
  fill: var(--white);
  margin: 0;
}

.pointer__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--blue-2);
  border-radius: 4px;
  fill: var(--white);
  transform: rotate(180deg);
  margin: 0;
}

.pointer__icon {
  display: flex;
  flex-shrink: 0;
}

.videos {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: normal;
}

.videos__link {
  display: flex;
  flex-direction: column;
  color: var(--black-2);
}

.videos__content {
  position: relative;
  display: flex;
}

.videos__img {
  width: 100%;
  height: auto;
}

.videos__date {
  color: var(--blue-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 0;
  transition: 0.3s;
}

.videos__title {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  align-self: stretch;
}

.videos__link:hover .icon {
  background-color: var(--blue-2);
  transition: 0.3s;
}

.videos__link:hover .videos__date {
  background-color: var(--blue-2);
  color: var(--white);
  font-weight: 600;
  padding: 10px;
  transition: 0.3s;
}

.texts {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 22px;
  line-height: 28px;
  max-width: 740px;
  align-self: center;
  margin: 0 auto;
}

.show-480 {
  display: none;
}

.show-530 {
  display: none;
}

.events__form {
  display: flex;
  gap: 20px;
}

.events {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.events__link {
  color: var(--black-2);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.events__images {
  position: relative;
  display: flex;
}

.events__img {
  display: flex;
  width: 100%;
  height: auto;
}

.events__icons {
  position: absolute;
  border-radius: 0 0 0 3px;
  background-color: var(--skyblue-2);
  padding: 5px;
  top: 0;
  right: 0;
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--white);
}

.events__icons--green {
  background-color: var(--green-2);
}

.events__date {
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

.events__icon {
  display: flex;
}

.events__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.events__text {
  font-weight: 600;
}

.competition {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.competition__link {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.competition__img {
  width: 100%;
  height: auto;
}

.competition__text {
  color: var(--black-2);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.filter {
  display: grid;
  gap: 20px;
}

.filter--6 {
  grid-template-columns: 250px 200px 40px;
}

.filter--5 {
  grid-template-columns: auto auto 100px 175px 40px;
}

.filter--4 {
  grid-template-columns: auto 205px 180px 40px;
  max-width: 840px;
  width: 100%;
}

.filter--3 {
  grid-template-columns: 22% 22% 6%;
}

.filter--2 {
  gap: 10px;
}

.filter--drop {
  grid-template-columns: 23% 23% 23% 23% 8%;
  width: calc(100% - 80px);
}

.filter__item {
  display: flex;
}

.dropdown {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--silver);
  position: relative;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  display: flex;
}

.dropdown:after {
  content: "";
  background-image: url("/public/images/drop-arrow-blue.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.dropdown__select {
  display: flex;
  width: 100%;
  background-color: transparent;
  color: var(--silver);
  border: none;
  justify-content: space-between;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select,
.select-blue {
  width: 100%;
}

.select__control,
.select-blue__control {
  cursor: pointer !important;
  min-height: 40px !important;
}

.select__control {
  border-color: var(--silver) !important;
}

.select-blue__control {
  border-color: var(--blue-2) !important;
  background-color: var(--blue-2) !important;
}

.select__single-value,
.select-blue__single-value {
  font-size: 16px;
  line-height: 20px;
}

.select-blue__single-value {
  color: var(--white) !important;
}

.select__indicator-separator,
.select-blue__indicator-separator {
  display: none!important;
}

.select__indicator,
.select-blue__indicator {
  transition: 0.3s;
}

.select__indicator svg {
  fill: var(--blue-2);
}

.select-blue__indicator svg {
  fill: var(--white);
}

.select__control--menu-is-open .select__indicator,
.select-blue__control--menu-is-open .select-blue__indicator {
  transition: 0.3s;
  transform: rotate(180deg);
}

.select__menu,
.select-blue__menu {
  border: 1px solid var(--blue-2);
  margin: 0 !important;
  z-index: 3 !important;
}

.select__multi-value,
.select-blue__multi-value {
  background-color: var(--aqua) !important;
}

.select__multi-value__label,
.select-blue__multi-value__label {
  color: var(--black-2) !important;
}

.select__multi-value__remove svg,
.select-blue__multi-value__remove svg {
  fill: var(--blue-2) !important;
}

.matches {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.match__link {
  display: flex;
  padding: 20px 10px;
  gap: 15px;
  color: var(--black-2);
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(var(--white-opacity), 0.95) 0%, rgba(var(--white-opacity), 0.95) 100%), url("/public/images/match-background.png") center / cover no-repeat, lightgray 50% center;
}

.match__link:hover {
  background: linear-gradient(0deg, rgba(var(--white-opacity), 0.95) 0%, rgba(var(--white-opacity), 0.95) 100%);
}

.match__content {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.match__name {
  text-align: center;
  font-weight: 600;
  max-width: 135px;
  width: 100%;
}

.match__numbers {
  display: flex;
  gap: 10px;
  align-items: center;
}

.match__number {
  color: var(--green-2);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-transform: uppercase;
}

.match__number--win {
  color: var(--green-2);
}

.match__number--draw {
  color: var(--blue-2);
}

.match__number--lose {
  color: var(--red);
}

.match__points {
  color: var(--blue-2);
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}

.match__container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.match__icon {
  display: flex;
}

.match__date {
  font-family: "Inter", "Arial", sans-serif;
  font-size: 14px;
  line-height: 16px;
}

.error {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 750px;
  align-items: center;
  margin: 0 auto;
  padding: 60px 0 0;
}

.error__title {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
}

.error__icon {
  display: flex;
  max-width: 100%;
  height: auto;
}

.error__subtitle {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
}

.error__text {
  font-family: "Inter", "Arial", sans-serif;
  line-height: 26px;
}

.error__link {
  color: var(--orange);
  font-weight: 600;
}

.images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.images__link {
  display: flex;
}

.arrows--photo .arrows__right,
.arrows--photo .arrows__left {
  background-color: var(--white);
  width: 80px;
  height: 80px;
}

.arrows--photo .arrows__icon {
  fill: var(--blue-2);
  width: 35px;
  height: auto;
}

.arrows--photo .arrows__left {
  margin: 0 0 0 -40px;
}

.arrows--photo .arrows__right {
  margin: 0 -40px 0 0;
}

.arrows--photo {
  top: calc(50% - 50px);
  z-index: 2;
}

.arrows--static .arrows__arrow {
  margin: 0;
}

.photo {
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 4;
  top: 160px;
  width: 100%;
  align-items: center;
}

.photo.active {
  display: flex;
}

.photo__container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 300px);
}

.photo__numbers {
  display: flex;
  border-radius: 4px;
  background: var(--white-2);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -25px;
  right: calc(50% - 28px);
  z-index: 2;
}

.photo__number {
  color: var(--black-2);
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 22px;
  line-height: 26px;
}

.video {
  display: flex;
  position: relative;
}

.video-iframe iframe {
  width: 100%;
  height: 535px;
}

.searching-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.searching {
  display: flex;
  gap: 20px;
}

.searching__input {
  border-radius: 4px;
  border: 1px solid var(--silver);
  background-color: var(--white);
  padding: 10px;
  width: 100%;
  max-width: 365px;
  color: var(--black-2);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.searching__input::placeholder {
  color: var(--silver);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.searching__button {
  gap: 20px;
  padding: 5px 10px 5px 38px;
}

.searching__text {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.searching__icon {
  flex-shrink: 0;
}

.school__titles {
  display: flex;
  gap: 10px;
}

.check {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.check__input {
  display: none;
}

.check__check {
  background-image: url("/public/images/check.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: none;
}

.check__indicator {
  width: 16px;
  height: 16px;
  border: 1px solid var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.check__input:checked + .check__indicator .check__check {
  display: block;
}

.check__text {
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.maps {
  display: flex;
  width: 100%;
  height: 575px;
  margin: 0 auto;
}

.maps__img {
  width: 100%;
  height: auto;
}

.balloon {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  min-width: 700px;
}

.balloon__texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  line-height: 20px;
  color: var(--white);
  width: 100%;
}

.balloon__title {
  font-weight: 600;
}

.balloon__address {
  font-family: "Inter", "Arial", sans-serif;
}

.balloon__infos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.balloon__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.balloon__label {
  min-width: 200px;
  font-weight: 600;
}

.balloon__text {
  font-family: "Inter", "Arial", sans-serif;
  min-width: 200px;
}

.balloon__work-hours {
  display: contents;
}

.balloon__work-hours__item {
  display: flex;
  gap: 5px;
}

.balloon__work-hours__day {
  min-width: 25px;
}

.balloon__social {
  display: contents;
}

.balloon__social__link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.balloon__image {
  position: absolute;
  right: 0;
}

.ymaps-2-1-79-balloon {
  box-shadow: 0 8px 24px 0 rgba(var(--black), 0.32), 0 4px 4px 0 rgba(var(--black), 0.08) !important;
  border-radius: 4px !important;
}

.ymaps-2-1-79-balloon__layout {
  background-color: var(--black-2) !important;
  border-radius: 4px;
}

.ymaps-2-1-79-balloon__content {
  padding: 0 !important;
  background-color: var(--black-2) !important;
}

.ymaps-2-1-79-balloon__content > ymaps {
  width: auto !important;
  height: auto !important;
}

.ymaps-2-1-79-balloon__tail:after {
  background-color: var(--black-2) !important;
}

.ymaps-2-1-79-balloon__close-button {
  background-image: url("/public/images/close.svg") !important;
  background-repeat: no-repeat!important;
  background-size: 15px 15px!important;
  opacity: 1!important;
}

.balloon__image {
  flex-shrink: 0;
  border-radius: 50%;
}

.balloon__group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 490px;
}

.ymaps-2-1-79-copyrights-pane {
  display: none !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: 0.3s;
}

.modal:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.76;
  background-color: var(--blue);
  z-index: 11;
}

.modal__content {
  z-index: 12;
  width: 100%;
  max-width: 550px;
  min-height: 330px;
  flex-shrink: 0;
  border-radius: 4px;
  background-image: url("/public/images/background-modal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--white);
  box-shadow: 0 5px 25px 0 rgba(var(--black), 0.10), 0 0 10px 0 rgba(var(--black), 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 30px 0 40px;
  justify-content: center;
}

.modal__content--no-background {
  background-image: none;
}

.modal__logos {
  display: flex;
}

.modal__title {
  color: var(--black-2);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  font-family: "Tilda Sans", "Arial", sans-serif;
  line-height: 26px;
  text-transform: uppercase;
}

.modal__text {
  color: var(--black-2);
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.modal__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.modal__wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal__button {
  color: var(--white);
  text-align: center;
  font-family: "Tilda Sans", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  background-color: var(--blue-2);
  text-transform: uppercase;
  padding: 10px 30px;
  max-width: 160px;
  width: 100%;
}

.modal__button:hover {
  background-color: var(--blue-3);
}

.modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal__icon {
  display: flex;
}

.top {
  display: flex;
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  height: 0;
  justify-content: flex-end;
}

.top__link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 65px;
  right: 20px;
}

.top__icon {
  fill: var(--blue);
  transition: 0.3s;
}

.top__link:hover .top__icon {
  fill: var(--skyblue);
  transition: 0.3s;
}

.address {
  width: 120px;
  min-height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  color: var(--white);
}

.cookie {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 3;
  margin: auto auto 0;
  width: 100%;
  border-radius: 4px;
  background-color: var(--aqua-2);
  display: flex;
  align-items: center;
  padding: 30px 20px;
  gap: 20px;
}

.cookie__texts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cookie__text {
  color: var(--black-2);
  font-size: 16px;
  font-family: "Inter", "Arial", sans-serif;
  line-height: 20px;
}

.cookie__buttons {
  display: flex;
  gap: 20px;
}

.cookie__button {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: var(--blue-2);
  padding: 8px 10px;
  white-space: nowrap;
}

.cookie__button--aqua {
  background-color: var(--blue-3);
}

.cookie__button:hover {
  background-color: var(--blue-3);
}

.cookie__button--aqua:hover {
  background-color: var(--blue-2);
}

.highlight {
  background-color: var(--beige);
}

.social__item {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.social__block {
  display: none;
  position: absolute;
  top: -30px;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}

.social__text {
  color: var(--white);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  background-color: var(--black-2);
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0 8px 24px 0 rgba(var(--black-opacity), 0.32), 0 4px 4px 0 rgba(var(--black-opacity), 0.08);
}

.search__arrow {
  margin: -2px 0 0 0;
}

.social__item:hover .social__block {
  display: flex;
  transition: 0.3s;
}

.social__item:last-child .social__block .search__arrow {
  margin: -2px -25px 0 0;
}

.social__item:last-child .social__block {
  left: -60px;
}

.video-player {
  position: relative;
}

.infos {
  padding: 10px 40px 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
}

.infos__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.infos__groups {
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-family: "Inter", "Arial", sans-serif;
}

.infos__group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.infos__type {
  font-size: 12px;
  line-height: 14px;
  padding: 3px 5px;
  color: var(--white);
  border-radius: 2px;
}

.infos__events {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.infos__event {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.infos__event:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--blue-2);
  flex-shrink: 0;
  margin: 5px 0 0;
}

.infos__wrap {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.infos__text {
  font-size: 14px;
  line-height: 18px;
}

.infos__link {
  color: var(--blue-2);
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
}

.infos__link:hover {
  color: var(--blue-3);
  transition: 0.3s;
}

.event {
  padding: 20px 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  width: 100%;
  flex-grow: 1;
  height: 100%;
}

.event__wrap {
  position: relative;
}

.event__img {
  width: 100%;
  height: auto;
}

.event__date {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  border-top-left-radius: 4px;
  background-color: var(--orange);
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", "Arial", sans-serif;
  font-weight: 500;
}

.event__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.event__text {
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", "Arial", sans-serif;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}

.links {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.links__link {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: var(--blue-2);
  background-color: rgba(var(--blue-2-opacity), 0.2);
  transition: 0.3s;
}

.links__link:hover {
  color: var(--white) !important;
  transition: 0.3s;
}

.modal__form {
  z-index: 12;
  max-width: 480px;
  flex-shrink: 0;
  border-radius: 4px;
  width: 100%;
  position: relative;
  align-items: center;
  padding: 30px 40px;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0 5px 25px 0 rgba(var(--black), 0.10), 0 0 10px 0 rgba(var(--black), 0.05);
}

.form {
  font-family: "Inter", "Arial", sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  align-items: center;
}

.form__title {
  color: var(--black-2);
  text-align: center;
  font-family: "Tilda Sans", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
}

.form__texts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form__info {
  color: var(--black-2);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.form__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.form__wrap--15 {
  gap: 15px;
}

.form__label {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  position: relative;
}

.form__text {
  color: var(--black-2);
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

.form__input {
  width: 100%;
  height: 40px;
  border: 1px solid var(--silver);
  border-radius: 4px;
  padding: 5px 10px;
  transition: 0.3s;
  color: var(--black-2);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.form__input:focus-visible {
  border: 1px solid var(--blue-2);
}

.form__input:hover {
  transition: 0.3s;
  border: 1px solid rgba(var(--silver-opacity), 0.50);
}

.form__input--error {
  border: 1px solid rgba(var(--red-2-opacity), 0.40);
}

.form__input--error ~ .form__warning {
  display: flex;
}

.form__warning {
  display: none;
  color: var(--red-2);
  align-self: end;
  text-align: right;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  bottom: -12px;
  position: absolute;
}

.form__input::placeholder {
  color: var(--silver);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.form__control {
  height: 10px;
}

.form__control {
  position: absolute;
  right: 15px;
  bottom: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../images/show-icon.svg) 0 0 no-repeat;
  transition: 0.3s;
}

.form__control--view {
  background: url(../images/hide-icon.svg) 0 0 no-repeat;
  transition: 0.3s;
}

.form__content {
  display: flex;
  flex-direction: column;
}

.form__button--big {
  max-width: 240px;
}

.form__block {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.form__check .check__text {
  font-size: 14px;
  line-height: 20px;
}

.modal__recovery {
  color: var(--blue-2);
  font-size: 14px;
  line-height: 20px;
}

.form__link {
  color: var(--blue-2);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.form__errors {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--red);
  min-height: 16px;
}

.modal.active {
  display: flex;
}

.personal {
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
  gap: 30px;
}

.personal__form {
  max-width: 400px;
}

.personal__form .form__wrap {
  gap: 15px;
}

.personal__profile {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 10px;
}

.personal__img {
  border-radius: 100%;
  height: 230px;
  width: 230px;
}

.personal__text {
  color: var(--dark-blue);
  font-family: "Inter", "Arial", sans-serif;
  font-size: 12px;
  line-height: 14px;
}

.personal__button {
  color: var(--blue-2);
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  background-color: white;
  margin: 10px 0 0;
}

.button--personal {
  background-color: var(--blue-5);
  align-self: flex-end;
  margin: 10px 0 0;
}

.personal__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.personal__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.personal__item {
  display: flex;
  gap: 10px;
  font-family: "Inter", "Arial", sans-serif;
  align-items: center;
  color: var(--black-2);
}

.personal__title {
  min-width: 85px;
}

.personal__wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.personal__subtitle {
  max-width: 310px;
}

.personal__absence {
  opacity: 0.5;
}

.personal__links {
  display: flex;
  gap: 5px;
  color: var(--blue-2);
}

.personal__connect {
  color: var(--blue-5);
  transition: 0.3s;
  position: relative;
  font-size: 16px;
  line-height: 20px;
}

.personal__connect:hover {
  text-decoration-line: underline;
  transition: 0.3s;
}

.personal__clue {
  transition: 0.3s;
  display: none;
  justify-content: center;
  color: var(--white);
  text-align: center;
  font-family: "Inter", "Arial", sans-serif;
  font-size: 12px;
  line-height: 14px;
  padding: 8px;
  background-color: var(--black);
  position: absolute;
  z-index: 3;
  border-radius: 4px;
  bottom: 28px;
  box-shadow: 0 4px 4px 0 rgba(var(--black-opacity), 0.08), 0 8px 24px 0 rgba(var(--black-opacity), 0.32);
}

.personal__clue:after {
  content: "";
  background-image: url("/src/assets/images/arrow-icon.svg");
  top: 44px;
  position: absolute;
  width: 29px;
  height: 6px;
}

.personal__connect:hover .personal__clue {
  display: flex;
  transition: 0.3s;
}

.personal__and {
  color: var(--black-2);
}

.personal__buttons {
  display: flex;
  gap: 20px;
  margin: 20px 0 0 auto;
  align-items: center;
}

.personal__block {
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
}

.personal__reduction {
  margin: 0;
  background-color: var(--white);
  color: var(--blue-5);
  padding: 0;
  min-height: auto;
  text-align: center;
  font-family: "Tilda Sans", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  transition: 0.3s;
}

.personal__exit {
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  text-transform: uppercase;
  max-width: none;
  font-family: "Tilda Sans", "Arial", sans-serif;
  background-color: var(--blue-5);
  transition: 0.3s;
  color: var(--white);
  text-align: center;
  width: 100%;
}

.form__button {
  max-width: 200px;
}

.personal__exit:hover {
  background-color: var(--blue-3);
  transition: 0.3s;
}

.personal__icon {
  transition: 0.3s;
  fill: var(--blue-5);
}

.personal__block:hover .personal__icon {
  fill: var(--blue-3);
  transition: 0.3s;
}

.personal__block:hover .personal__reduction {
  color: var(--blue-3);
  transition: 0.3s;
}

.personal__wrapper {
  position: relative;
}

.personal__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.cropper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cropper__buttons {
  display: flex;
  gap: 10px;
}

.cropper__button {
  z-index: 100;
}
