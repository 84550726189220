@media (max-width: 480px) {
  .header__container {
    padding: 7px 10px;
  }

  .main {
    padding: 10px 10px 160px;
    background-position-x: left;
    margin: 54px 0 0;
  }

  .footer__list {
    text-align: start;
    align-self: self-start;
    margin: 10px 0 0;
  }

  .footer__links {
    gap: 10px;
    flex-direction: column;
    order: -1;
    margin: 10px 0 0;
  }

  .footer__partner {
    color: var(--skyblue);
  }

  .footer__copyright {
    margin: 10px 0 0;
  }

  .footer__container {
    gap: 30px;
  }

  .footer__text {
    line-height: 18px;
  }

  .footer__block {
    align-items: center;
  }

  .new__date {
    font-size: 14px;
  }

  .new__item:nth-child(1) .new__date {
    font-size: 14px;
  }

  .title {
    font-size: 26px;
    text-align: center;
  }

  .button-link {
    background-color: var(--blue);
    max-width: none;
    width: 100%;
  }

  .button-link__text {
    color: var(--white);
    font-size: 16px;
  }

  .button-link__icon {
    fill: var(--white);
  }

  .content {
    grid-template-columns: 1fr;
  }

  .partners {
    gap: 20px;
    grid-template-columns: 1fr;
  }

  .footer {
    padding: 30px 10px 35px;
    gap: 30px;
  }

  .aside {
    gap: 30px;
  }

  .aside__title {
    font-size: 20px;
    line-height: 26px;
  }

  .ballot__form {
    gap: 30px;
  }

  .table-border {
    min-width: 460px;
  }

  .table-border--small {
    min-width: auto;
  }

  .nav {
    top: 54px;
    height: calc(100vh - 55px);
  }

  .nav__list {
    padding: 20px 10px;
  }

  .nav__footer {
    padding: 20px 10px 25px;
    gap: 25px;
  }

  .nav__block {
    gap: 15px;
  }

  .promo__title {
    font-size: 18px;
    line-height: 20px;
  }

  .promo__subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .hashlink__link {
    font-size: 12px;
    line-height: 16px;
  }

  .promo {
    gap: 15px;
  }

  .promo__content {
    gap: 15px;
  }

  .btn {
    margin: 15px 0 0;
    width: 100%;
    max-width: none;
  }

  .promo__date {
    font-size: 12px;
    line-height: 16px;
  }

  .about__name {
    font-size: 18px;
    line-height: 20px;
  }

  .about__post {
    font-size: 12px;
    line-height: 16px;
  }

  .about__item {
    gap: 10px;
    align-items: center;
  }

  .about__img {
    width: 110px;
  }

  .gap--60 {
    gap: 50px;
  }

  .gap--40 {
    gap: 25px;
  }

  .buttons__link {
    padding: 5px 20px;
  }

  .about__post:before {
    margin: 0 0 0 -10px;
  }

  .gap--30 {
    gap: 25px;
  }

  .contacts__title {
    font-size: 18px;
    line-height: 22px;
    gap: 10px;
  }

  .contacts__link {
    font-size: 16px;
    line-height: 20px;
    gap: 5px;
  }

  .contacts__content {
    gap: 15px;
  }

  .contacts {
    gap: 15px;
  }

  .map {
    gap: 15px;
  }

  .map__container {
    height: 180px;
  }

  .requisite__indication {
    font-weight: 600;
  }

  .requisite__name {
    font-size: 16px;
    text-align: start;
  }

  .requisite__item {
    justify-content: normal;
    gap: 10px;
    flex-direction: column;
    padding: 10px 0;
  }

  .requisite {
    gap: 5px;
  }

  .subtitle {
    font-size: 24px;
    line-height: 26px;
  }

  .gap--20 {
    gap: 10px;
  }

  .sorts__label {
    font-size: 14px;
    padding: 5px 25px;
  }

  .news__buttons {
    gap: 15px;
  }

  .choice {
    gap: 15px;
  }

  .news__subtitle {
    line-height: 20px;
  }

  .news__title {
    font-size: 22px;
  }

  .news {
    gap: 30px;
  }

  .news__content {
    gap: 10px;
  }

  .news__ref:hover .news__date {
    padding: 10px 15px 5px 0;
  }

  .aside__list {
    gap: 10px;
  }

  .hashtags__text {
    font-weight: 600;
  }

  .news__date {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 15px 5px 0;
  }

  .data {
    gap: 5px;
  }

  .data__text:not(:first-child) {
    gap: 5px;
    font-size: 12px;
  }

  .data__text:not(:first-child):before {
    display: none;
  }

  .data__text:last-child:before {
    content: "";
    width: 2px;
    height: 100%;
    display: block;
    background-color: var(--blue-2);
    flex-shrink: 0;
  }

  .data__text {
    font-size: 14px;
  }

  .documents__icon {
    width: 40px;
    height: 40px;
  }

  .documents__title {
    font-size: 26px;
    text-align: center;
  }

  .documents__subtitle {
    font-size: 18px;
  }

  .arrows {
    display: none;
  }

  .texts {
    font-size: 20px;
    line-height: 26px;
  }

  .hide-480 {
    display: none;
  }

  .show-480 {
    display: block;
  }

  .events {
    grid-template-columns: 100%;
  }

  .events__form .reset {
    align-self: end;
  }

  .competition {
    grid-template-columns: 1fr;
  }

  .gap--35 {
    gap: 25px;
  }

  .filter--5 {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 27% 49% 14%;
    gap: 15px;
  }

  .filter--5 .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--5 .filter__item:nth-child(2) {
    grid-column: 1/4;
    grid-row: 2/3;
  }

  .filter--5 .filter__item:nth-child(3) {
    grid-area: 3/1;
  }

  .filter--5 .filter__item:nth-child(4) {
    grid-area: 3/2;
  }

  .filter--5 .filter__item:nth-child(5) {
    grid-area: 3/3;
  }

  .filter--drop {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    width: 100%;
  }

  .filter--drop .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--drop .filter__item:nth-child(2) {
    grid-column: 1/4;
  }

  .filter--drop .filter__item:nth-child(3) {
    grid-column: 1/4;
  }

  .filter--drop .filter__item:nth-child(4) {
    grid-column: 1/3;
  }

  .filter--3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .filter--3 .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--3 .filter__item:nth-child(2) {
    grid-column: 1/3;
  }

  .filter--6 {
    grid-template-columns: auto 40px;
    grid-template-rows: repeat(2, 1fr);
  }

  .filter--6 .filter__item:nth-child(1) {
    grid-column: 1/3;
  }

  .filter--6 .filter__item:nth-child(2) {
    grid-column: 1/2;
  }

  .filter--6 .filter__item:nth-child(3) {
    grid-column: 2/3;
  }

  .table__300 {
    width: 160px;
  }

  .match__number {
    font-size: 30px;
    line-height: 38px;
  }

  .match__name {
    font-size: 16px;
  }

  .match__date {
    font-size: 12px;
    line-height: 14px;
  }

  .match__link {
    padding: 10px 5px;
  }

  .match__content {
    gap: 5px;
  }

  .header-page {
    gap: 5px;
  }

  .documents {
    gap: 15px;
  }

  .error {
    padding: 55px 0 0;
  }

  .error__title {
    font-size: 24px;
    line-height: 26px;
  }

  .photo__container {
    width: 100%;
    padding: 0 10px;
  }

  .photo__numbers {
    padding: 5px 10px;
  }

  .photo__number {
    font-size: 18px;
    line-height: 20px;
  }

  .icon-video__play {
    width: 50px;
    height: 50px;
  }

  .video-iframe iframe {
    height: calc((100vw - 25px) * 9 / 16);
  }

  .searching {
    gap: 15px;
  }

  .searching__text {
    display: none;
  }

  .searching__button {
    padding: 10px;
  }

  .school__titles {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .filter--4 {
    grid-template-columns: 1fr 1fr 1fr;
  }


  .filter--4 .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--4 .filter__item:nth-child(2) {
    grid-column: 1/4;
  }

  .filter--4 .filter__item:nth-child(3) {
    grid-column: 1/3;
  }

  .filter--4 .filter__item:nth-child(4) {
    grid-area: 3/3;
  }

  .balloon__texts {
    font-size: 14px;
    line-height: 18px;
  }

  .top__link {
    bottom: 45px;
    right: 10px;
  }

  .cookie__buttons {
    flex-direction: column;
    gap: 5px;
  }

  .cookie__texts {
    gap: 5px;
  }

  .cookie__text {
    font-size: 12px;
    line-height: 16px;
  }

  .cookie__button {
    font-size: 14px;
    line-height: 18px;
  }

  .cookie {
    padding: 10px;
    gap: 10px;
  }

  .modal__title {
    font-size: 18px;
    line-height: 20px;
  }

  .modal__text {
    font-size: 14px;
    line-height: 18px;
  }

  .modal__container {
    gap: 25px;
  }

  .modal__wrap {
    gap: 10px;
  }

  .modal__content {
    gap: 25px;
  }

  .balloon {
    height: 175px;
  }

  .infos {
    padding: 10px 10px 0;
  }

  .infos__title {
    font-size: 16px;
    line-height: 22px;
  }

  .links {
    gap: 10px;
    justify-content: center;
  }

  .form__check .check__text {
    font-size: 12px;
  }

  .modal__recovery {
    font-size: 12px;
  }

  .modal__form {
    padding: 30px 20px 20px;
  }

  .form {
    gap: 20px;
  }

  .form__wrap {
    gap: 15px;
  }

  .form__wrap--15 {
    gap: 10px;
  }

  .form__text {
    font-size: 12px;
    line-height: 14px;
  }

  .form__title {
    font-size: 16px;
  }

  .modal__icon {
    width: 20px;
    height: 20px;
  }

  .form__info {
    font-size: 14px;
    line-height: 20px;
  }

  .form__button {
    max-width: none;
  }

  .form__texts {
    gap: 5px;
    padding: 0 5px;
  }

  .personal {
    margin: 20px auto;
  }

  .personal__img {
    width: 150px;
    height: 150px;
  }

  .personal__button {
    margin: 0;
  }

  .personal__buttons {
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin: 15px 0 0;
  }

  .personal__block {
    width: 100%;
    justify-content: center;
    padding: 8px 0;
  }

  .personal__exit {
    font-size: 14px;
    line-height: 16px;
  }

  .personal__links {
    flex-wrap: wrap;
  }

  .personal__container {
    gap: 15px;
    font-size: 14px;
    line-height: 18px;
  }

  .personal__connect {
    font-size: 14px;
    line-height: 18px;
  }

  .personal__list {
    gap: 5px;
  }

  .personal__title {
    min-width: 70px;
  }

  .personal__item {
    gap: 5px;
  }

  .personal__reduction {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 400px) {
  .new__ref {
    flex-direction: column;
  }

  .new__container {
    gap: 10px;
  }

  .new__img {
    width: 100%;
    max-width: 100%;
  }

  .new__item:nth-child(1) .new__ref {
    flex-direction: column;
  }

  .new__item:nth-child(1) .new__img {
    width: 100%;
  }

  .new__item:nth-child(1) .new__container {
    gap: 10px;
  }

  .contacts__content {
    flex-direction: column;
  }

  .map__container {
    height: 135px;
  }

  .balloon__infos {
    gap: 5px;
  }
}

