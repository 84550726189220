.react-datepicker {
  background-color: var(--white);
  border: 1px solid var(--blue-2);
  border-radius: 0;
  position: relative;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__month-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.react-datepicker__day-names {
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.react-datepicker__week {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  height: 100%;
}

.custom-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  justify-content: space-between;
}

.custom-header__column {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--blue-2);
  position: relative;
}

.custom-header__month:hover {
  border-radius: 4px;
  background-color: var(--blue-3) !important;
}

.custom-header__years {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  position: absolute;
  padding: 4px 2px 0;
}

.custom-header__year {
  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.custom-header__year--active {
  opacity: 1;
}

.custom-header__months {
  display: flex;
  align-items: end;
  color: var(--white);
  height: 100%;
}

.custom-header__month {
  width: 32px;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
}

.custom-header__month--active {
  color: var(--white);
  border-radius: 4px;
  background-color: var(--skyblue);
}

.custom-header__arrow {
  background-color: var(--blue-2);
  fill: var(--white);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.custom-header__arrow:disabled {
  background-color: var(--grey);
  cursor: auto;
}

.custom-header__icon {
  height: 20px;
  width: 10px;
}

.custom-header__icon--right {
  transform: rotate(180deg);
}

.react-datepicker__header {
  background-color: var(--white);
  border: none;
  padding: 0;
}

.react-datepicker__day-name {
  width: 36px;
  height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--blue-2);
  text-align: center;
  font-size: 10px;
  line-height: 14px;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--black-2);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0.166rem 0;
}

.react-datepicker__day--in-selecting-range {
  background-color: var(--skyblue-3);
  color: var(--dark-blue);
  border-radius: 0;
}

.react-datepicker__day--keyboard-selected:hover {
  border-radius: 100%;
  background-color: var(--blue-4);
  margin: 0;
  color: var(--white);
}

.react-datepicker__day--in-selecting-range {
  background-color: var(--skyblue-3);
}

.react-datepicker__month-text--selected {
  background-color: var(--skyblue-3);
  border-radius: 0;
}

.react-datepicker__day--in-range {
  border-radius: 0;
  background-color: var(--skyblue-3);
  color: var(--dark-blue);
}

.react-datepicker__day--today {
  background-color: var(--blue-4) !important;
  border-radius: 100%;
  color: var(--white);
}

.react-datepicker__day--selecting-range-start {
  background-color: var(--blue-4) !important;
  border-radius: 100%;
  color: var(--white);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: var(--skyblue-3);
}

.react-datepicker__day—selecting-range-end {
  background-color: var(--blue-4) !important;
  border-radius: 100%;
}

.react-datepicker__day--in-selecting-range:hover {
  border-radius: 100%;
  background-color: var(--blue-4);
  margin: 0;
  color: var(--white);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--blue-4);
  color: var(--white);
  position: relative;
}

.react-datepicker__day:hover {
  margin: 0;
  border-radius: 100%;
}

.react-datepicker__day--outside-month {
  color: var(--silver);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range:hover {
  border-radius: 100%;
  background-color: var(--blue-4);
  margin: 0;
  color: var(--white);
  position: relative;
  z-index: 2;
}

.react-datepicker__day--keyboard-selected:after {
  content: '';
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: var(--skyblue-3);
  border-radius: 50% 0 0 50%;
  z-index: -2;
}

.react-datepicker__day--keyboard-selected:before {
  content: '';
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: var(--blue-4);
  border-radius: 100%;
  z-index: -1;
}

.react-datepicker__day--range-end:after {
  content: '';
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: var(--skyblue-3);
  border-radius: 0 50% 50% 0;
  z-index: -2;
}

.react-datepicker__day--range-end:before {
  content: '';
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: var(--blue-4);
  border-radius: 100%;
  z-index: -1;
}

.react-datepicker-popper {
  z-index: 2;
}

@media (max-width: 780px) {
  .react-datepicker-popper {
    margin: 0 0 0 -60px;
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .react-datepicker__month-container {
    padding: 20px 24px;
  }

  .custom-header {
    padding: 0 0 20px 0;
  }

  .react-datepicker-popper {
    margin: 0;
  }

  .custom-header__column {
    flex-direction: row;
    background-color: white;
    color: var(--black-2);
    height: auto;
    gap: 5px;
  }

  .custom-header__years {
    position: static;
    order: 1;
    padding: 0
  }

  .custom-header__month:hover {
    border-radius: 0;
    background-color: white !important;
  }

  .custom-header__year {
    color: var(--black-2);
  }

  .custom-header__year:not(.custom-header__year--active) {
    display: none;
  }

  .custom-header__month {
    color: var(--black-2);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    padding: 0;
  }

  .custom-header__month:not(.custom-header__month--active) {
    display: none;
  }

  .custom-header__months {
    display: flex;
    align-items: end;
    color: var(--black-2);
    height: 100%;
  }

  .custom-header__month--active {
    color: var(--black-2);
    background-color: var(--white);
  }

  .custom-header__icon {
    height: 12px;
    width: 6px;
  }

  .custom-header__arrow {
    width: 25px;
    height: 25px;
  }
}
