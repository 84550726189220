@media (max-width: 1810px) {
  .header__container {
    margin: 0;
  }
}

@media (max-width: 1710px) {
  .header__container {
    margin: 0 110px 0 0;
  }

  .navigation {
    padding: 15px 10px;
    min-width: 110px;
  }

  .navigation__wrapper {
    gap: 25px;
    max-width: 90px;
  }

  .navigation__link {
    flex-direction: column;
    gap: 5px;
  }

  .navigation__list {
    gap: 25px;
  }

  .navigation__text {
    font-size: 14px;
    line-height: 16px;
    text-transform: lowercase;
  }

  .open .navigation__link {
    flex-direction: row;
    gap: 20px;
  }

  .open .navigation__text {
    min-width: 90px;
  }

  .open .navigation__border {
    margin: 0;
  }

  .open .navigation__list {
    gap: 35px;
  }
}

@media (max-width: 1500px) {
  .map__container {
    height: 420px;
  }

  .maps {
    height: 535px;
  }

  .helper-info {
    bottom: -30px;
    max-width: 680px;
  }

  .date__input {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1400px) {
  .header__content {
    gap: 30px;
  }

  .promo {
    gap: 30px;
  }

  .map__container {
    height: 370px;
  }

  .maps {
    height: 500px;
  }

  .promo__container {
    max-width: 400px;
  }
}

@media (max-width: 1300px) {
  .header__container {
    padding: 15px 30px;
  }

  .main {
    padding: 30px 35px 200px;
    margin: 75px 0 0;
  }

  .footer {
    padding: 35px 35px 20px;
  }

  .footer__list {
    column-gap: 15px;
  }

  .footer__links {
    gap: 30px;
  }

  .partners {
    grid-template-columns: repeat(4, 1fr);
  }

  .table__th {
    padding: 7px 5px;
  }

  .table__cell {
    padding: 10px 5px;
  }

  .promo {
    gap: 20px;
    flex-direction: column;
    position: relative;
  }

  .promo__wrap {
    width: 100%;
    height: auto;
    margin: 0 0 47px 0;
  }

  .helper-info {
    bottom: 0;
    max-width: 775px;
  }

  .promo__container {
    max-width: none;
  }

  .promo__background {
    left: -30px;
    top: auto;
    bottom: 0;
    width: 100%;
  }

  .partners--four {
    grid-template-columns: repeat(3, 1fr);
  }

  .contacts {
    flex-direction: column;
    align-items: start;
    gap: 30px;
  }

  .contacts__content {
    gap: 30px;
  }

  .map__container {
    height: 320px;
  }

  .choice {
    flex-wrap: wrap;
  }

  .filter--drop {
    gap: 15px;
    width: calc(100% - 60px);
  }

  .photo {
    top: 140px;
  }

  .arrows--photo {
    top: calc(50% - 30px);
  }

  .maps {
    height: 470px;
  }

  .filter--5 {
    grid-template-columns: auto auto 60px 135px 40px;
  }
}

@media (max-width: 1180px) {
  .main {
    padding: 25px 30px 190px;
  }

  .main__wrap {
    grid-template-columns: 100%;
    gap: 30px;
  }

  .header__content {
    gap: 20px;
  }

  .header__container {
    padding: 15px 20px;
    gap: 15px;
  }

  .footer {
    padding: 35px 25px;
  }

  .footer__list {
    column-gap: 10px;
  }

  .footer__links {
    gap: 25px;
  }

  .new {
    grid-template-columns: 1fr 55%;
  }

  .table__th {
    padding: 7px 10px;
  }

  .table__cell {
    padding: 10px;
  }

  .promo__background {
    left: -25px;
  }

  .promo__title {
    font-size: 26px;
    line-height: 28px;
  }

  .about {
    grid-template-columns: 1fr;
  }

  .about__item {
    max-width: none;
  }

  .about__img {
    width: 150px;
  }

  .partners--four {
    grid-template-columns: repeat(4, 1fr);
  }

  .contacts__title {
    font-size: 24px;
    line-height: 28px;
  }

  .map__container {
    height: 450px;
  }

  .match__content {
    gap: 25px;
  }

  .match__numbers {
    gap: 5px;
  }

  .header-page {
    gap: 20px;
  }

  .arrows--photo .arrows__left,
  .arrows--photo .arrows__right{
    width: 70px;
    height: 70px;
  }

  .arrows--photo .arrows__left {
    margin: 0 0 0 -35px;
  }

  .arrows--photo .arrows__right {
    margin: 0 -35px 0 0;
  }

  .video-iframe iframe {
    height: calc((100vw - 165px)*9/16);
  }

  .maps {
    height: 445px;
  }

  .filter--5 {
    grid-template-columns: auto auto 100px 175px 40px;
  }

  .social__item:hover .social__block {
    display: none;
  }

  .infos {
    padding: 10px 35px 0;
  }

  .modal__form {
    padding: 30px 35px 35px;
  }

  .form__title {
    font-size: 22px;
  }

  .personal {
    margin: 40px auto;
  }

  .personal__img {
    width: 210px;
    height: 210px;
  }
}

@media (max-width: 1080px) {
  .main {
    padding: 25px 25px 190px;
    background-position-x: center;
    margin: 70px 0 0;
  }

  .header__container {
    margin: 0;
  }

  .hide {
    display: none;
  }

  .show {
    display: flex;
  }

  .navigation {
    display: none;
  }

  .search-mobile {
    display: flex;
  }

  .burger {
    flex-shrink: 0;
    display: block;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    outline: none;
    padding: 5px;
  }

  .burger span {
    width: 100%;
    background-color: var(--white);
    height: 2px;
    display: block;
    position: relative;
  }

  .burger span:after,
  .burger span:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--white);
  }

  .burger span:after {
    bottom: -8px;
  }

  .burger span:before {
    top: -8px;
  }

  .burger.open span {
    background: transparent;
  }

  .burger.open span:before,
  .burger.open span:after {
    transition-duration: 0.2s, 0.2s;
    transition-delay: 0.2s, 0s;
  }

  .burger.open span:before {
    transition-property: top, transform;
  }

  .burger.open span:after {
    transition-property: bottom, transform;
  }

  .burger.open span:before {
    top: 0;
    transform: rotate(45deg);
  }

  .burger.open span:after {
    bottom: 0;
    transform: rotate(-45deg);
  }

  .burger.open span:before,
  .burger.open span:after {
    transition-delay: 0s, 0.2s;
  }

  .footer__list {
    grid-template-columns: 1fr;
  }

  .footer__content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer__item:nth-child(2n) {
    order: 2;
  }

  .new__item:nth-child(1) .new__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
  }

  .aside__title {
    text-align: start;
  }

  .ballot {
    background-position: bottom;
  }

  .ballot__form {
    max-width: none;
    gap: 40px;
  }

  .nav {
    top: 70px;
  }

  .promo__title {
    font-size: 24px;
    line-height: 26px;
  }

  .about__img {
    width: 140px;
  }

  .map__container {
    height: 410px;
  }

  .choice {
    flex-wrap: nowrap;
  }

  .video-iframe iframe {
    height: calc((100vw - 45px)*9/16);
  }

  .cookie {
    padding: 25px 15px;
  }

  .balloon {
    min-width: 650px;
  }

  .tags__menu {
    top: auto;
    width: 220px;
    z-index: 12;
    flex-shrink: 0;
    position: relative
  }

  .tags__icon {
    transform: rotate(-180deg);
  }

  .profile__button {
    padding: 10px 15px;
    max-width: none;
    width: 100%;
  }

  .form__title {
    font-size: 20px;
  }
}

@media (max-width: 980px) {
  .main {
    padding: 20px 20px 180px;
  }

  .content {
    column-gap: 20px;
  }

  .footer {
    padding: 35px 20px;
  }

  .footer__links {
    gap: 20px;
  }

  .partners {
    grid-template-columns: repeat(3, 1fr);
  }

  .new__item:nth-child(1) .new__title {
    font-size: 18px;
    line-height: 20px;
  }

  .new__item:nth-child(1) .new__date {
    font-size: 14px;
    line-height: 18px;
  }

  .promo__background {
    left: -20px;
  }

  .promo__title {
    font-size: 22px;
    line-height: 24px;
  }

  .gap--40 {
    gap: 35px;
  }

  .about__img {
    width: 130px;
  }

  .contacts__title {
    font-size: 22px;
    line-height: 26px;
  }

  .contacts__content {
    gap: 25px;
  }

  .contacts {
    gap: 25px;
  }

  .map {
    gap: 25px;
  }

  .map__container {
    height: 380px;
  }

  .documents__icon {
    width: 50px;
    height: 50px;
  }

  .arrows__right {
    margin: 0 -15px 0 0;
  }

  .arrows__left {
    margin: 0 0 0 -15px;
  }

  .filter--drop {
    grid-template-columns: 25% 25% 25% 25%;
    width: calc(100% - 45px);
  }

  .match__number {
    font-size: 34px;
    line-height: 42px;
  }

  .match__content {
    gap: 15px;
  }

  .header-page {
    gap: 15px;
  }

  .error__title {
    font-size: 28px;
    line-height: 30px;
  }

  .images {
    gap: 20px;
  }

  .photo {
    top: 120px;
  }

  .arrows--photo .arrows__right,
  .arrows--photo .arrows__left {
    width: 60px;
    height: 60px;
  }

  .arrows--photo .arrows__left {
    margin: 0 0 0 -30px;
  }

  .arrows--photo .arrows__right {
    margin: 0 -30px 0 0;
  }

  .icon-video__play {
    width: 70px;
    height: 70px;
  }

  .video-iframe iframe {
    height: calc((100vw - 35px)*9/16);
  }

  .maps {
    height: 420px;
  }

  .cookie {
    padding: 20px 15px;
  }

  .cookie__buttons {
    gap: 15px;
  }

  .modal__title {
    font-size: 22px;
    line-height: 24px;
  }

  .balloon {
    min-width: 600px;
  }

  .filter--6 {
    grid-template-columns: auto auto 40px;
  }

  .infos {
    padding: 10px 30px 0;
  }

  .modal__form {
    padding: 30px;
  }

  .personal {
    margin: 30px auto;
  }

  .personal__img {
    width: 190px;
    height: 190px;
  }

  .personal__title {
    min-width: 80px;
  }
}

@media (max-width: 880px) {
  .hide-880 {
    display: none;
  }

  .info {
    display: none;
  }

  .promo__line {
    display: none;
  }

  .promo__wrap {
    margin: 0;
  }

  .map__container {
    height: 340px;
  }

  .maps {
    height: 380px;
  }

  .filter--5 {
    grid-template-columns: auto auto 60px 135px 40px;
  }
}
