@media (max-width: 780px) {
  .header__container {
    padding: 10px 20px;
  }

  .main {
    padding: 15px 15px 170px;
    margin: 60px 0 0;
  }

  .content {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
  }

  .partners {
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }

  .footer {
    padding: 35px 15px;
  }

  .footer__img {
    display: none;
  }

  .footer__container {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .footer__list {
    text-align: center;
    order: 2;
  }

  .footer__links {
    gap: 15px;
  }

  .footer__copyright {
    text-align: center;
  }

  .new {
    grid-template-columns: 1fr;
  }

  .new__item:nth-child(1) .new__ref {
    flex-direction: row;
  }

  .new__item:nth-child(1) .new__subtitle {
    display: none;
  }

  .new__item:nth-child(1) .new__img {
    width: 190px;
  }

  .new__item:nth-child(1) .new__title {
    font-size: 16px;
    line-height: 18px;
  }

  .new__item:nth-child(1) .hashtags__texts {
    font-size: 12px;
    font-weight: 500;
  }

  .new__item:nth-child(1) .new__date {
    font-size: 12px;
    line-height: 16px;
  }

  .new__item:nth-child(1) .new__container {
    gap: initial;
  }

  .new__list {
    grid-template-columns: 1fr;
  }

  .new__item:nth-child(1) {
    grid-column: auto;
    grid-row: auto;
  }

  .ballot__form {
    gap: 35px;
  }

  .nav__list {
    padding: 25px 15px;
  }

  .nav__footer {
    padding: 25px 15px;
  }

  .promo__title {
    font-size: 20px;
    line-height: 22px;
  }

  .hashlink__link {
    font-size: 14px;
    line-height: 18px;
  }

  .promo__date {
    font-size: 14px;
    line-height: 18px;
  }

  .gap--60 {
    gap: 55px;
  }

  .gap--40 {
    gap: 30px;
  }

  .about__img {
    width: 120px;
  }

  .contacts__title {
    font-size: 20px;
    line-height: 24px;
    gap: 15px;
  }

  .contacts__link {
    font-size: 18px;
  }

  .contacts__content {
    gap: 20px;
  }

  .contacts {
    gap: 20px;
  }

  .map {
    gap: 20px;
  }

  .map__container {
    height: 300px;
  }

  .requisite__indication {
    font-size: 18px;
    line-height: 20px;
  }

  .subtitle {
    font-size: 26px;
    line-height: 24px;
  }

  .gap--20 {
    gap: 15px;
  }

  .choice {
    flex-wrap: wrap;
  }

  .sorts__label {
    padding: 5px 30px;
  }

  .news__img {
    width: 100%;
    max-width: 100%;
  }

  .news__ref:hover .news__date {
    background-color: initial;
    color: var(--blue-2);
    margin: 0;
    padding: 0;
  }

  .news__ref {
    flex-direction: column;
  }

  .data__text:not(:first-child) {
    font-size: 14px;
  }

  .documents {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .documents__icon {
    width: 45px;
    height: 45px;
  }

  .choice {
    flex-wrap: wrap;
  }

  .sorts__label {
    padding: 5px 30px;
  }

  .news__img {
    width: 100%;
    max-width: 100%;
  }

  .events {
    grid-template-columns: repeat(2, 1fr);
  }

  .competition {
    grid-template-columns: repeat(2, 1fr);
  }

  .gap--35 {
    gap: 30px;
  }

  .competition .date {
    max-width: none;
  }

  .filter--5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .filter--drop {
    grid-template-columns: 33% 33% 34%;
    width: calc(100% - 30px);
  }

  .filter--3 {
    grid-template-columns: 40% 40% 10%;
  }

  .header-page {
    gap: 10px;
  }

  .error__subtitle {
    font-size: 22px;
  }

  .error__title {
    font-size: 26px;
    line-height: 28px;
  }

  .images {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .arrows--photo .arrows__arrow {
    width: 50px;
    height: 50px;
  }

  .arrows--photo .arrows__arrow {
    margin: 0 0 0 -25px;
  }

  .arrows--photo .arrows__arrow--right {
    margin: 0 -25px 0 0;
  }

  .photo__numbers {
    padding: 10px 15px;
  }

  .arrows--photo {
    top: calc(50% - 30px);
  }

  .photo__container {
    width: calc(100% - 150px);
  }

  .photo__number {
    font-size: 20px;
    line-height: 22px;
  }

  .icon-video__play {
    width: 60px;
    height: 60px;
  }

  .video-iframe iframe {
    height: calc((100vw - 30px)*9/16);
  }

  .maps {
    height: 320px;
  }

  .filter--4 {
    grid-template-columns: 1fr 1fr;
  }

  .cookie {
    flex-direction: column;
    padding: 15px 10px;
    gap: 15px;
  }

  .cookie__button {
    max-width: none;
    width: 100%;
  }

  .cookie__buttons {
    width: 100%;
    gap: 10px;
  }

  .cookie__text {
    font-size: 14px;
    line-height: 18px;
  }

  .modal__title {
    font-size: 20px;
    line-height: 22px;
  }

  .modal__text {
    font-size: 16px;
    line-height: 20px;
  }

  .modal__button {
    font-size: 14px;
    line-height: 18px;
  }

  .balloon {
    height: 230px;
    overflow: hidden scroll;
  }

  .balloon__texts {
    gap: 15px;
  }

  .balloon__group {
    max-width: 470px;
  }

  .nav {
    top: 60px;
  }

  .infos {
    padding: 10px 25px 0;
  }

  .links {
    gap: 15px;
  }

  .buttons-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .modal__form {
    padding: 30px 25px 25px;
  }

  .form {
    gap: 25px;
  }

  .form__title {
    font-size: 18px;
  }

  .form__info {
    font-size: 16px;
    line-height: 22px;
  }

  .personal {
    gap: 20px;
  }

  .personal {
    margin: 25px auto;
  }

  .personal__img {
    width: 170px;
    height: 170px;
  }

  .personal__list {
    gap: 10px;
  }

  .personal__title {
    min-width: 75px;
  }
}

@media (max-width: 680px) {
  .header__container {
    padding: 10px 15px;
  }

  .main__background {
    background-image: url("/public/images/background-piter-2.png");
  }

  .aside__title {
    text-align: center;
  }

  .map__container {
    height: 260px;
  }


  .arrows__right {
    margin: 0 -10px 0 0;
  }

  .arrows__left {
    margin: 0 0 0 -10px;
  }

  .matches {
    grid-template-columns: 1fr;
  }

  .match__number {
    font-size: 32px;
    line-height: 40px;
  }

  .match__link {
    padding: 15px 10px;
  }

  .match__content {
    gap: 10px;
  }

  .command {
    gap: 55px;
  }

  .photo {
    top: 100px;
  }

  .maps {
    height: 290px;
  }

  .filter--5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .ymaps-2-1-79-balloon__content {
    background-color: var(--white) !important;
  }

  .ymaps-2-1-79-balloon__layout {
    background-color: var(--white) !important;
  }

  .balloon__texts {
    color: var(--black-2);
  }

  .balloon__social__link {
    color: var(--black-2);
  }

  .balloon__label {
    color: var(--blue-2);
  }
  .balloon {
    overflow: scroll;
  }

  .balloon__image {
    position: static;
  }

  .ymaps-2-1-79-balloon__close-button {
    background-image: url("/public/images/close-black.svg") !important;
  }

  .balloon {
    min-width: 500px;
  }

  .ymaps-2-1-79-balloon__tail:after {
    background-color: var(--white) !important;
  }

  .balloon__social__text {
    display: none;
  }

  .balloon__label {
    min-width: 100px;
  }

  .balloon__text {
    min-width: 100px;
  }

  .balloon__group {
    max-width: none;
  }

  .infos {
    padding: 10px 20px 0;
  }

  .personal {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  .personal__form {
    max-width: none;
  }

  .personal__container {
    width: 100%;
  }
}

@media (max-width: 580px) {
  .main {
    background-position-x: center;
    background-image: url("/public/images/background-piter-2.png");
  }

  .aside__title {
    text-align: center;
  }

  .buttons__item {
    border-left: none;
  }

  .buttons__link {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    transition: 0.3s;
    background-color: var(--blue-2);
    color: var(--white);
    border-radius: 3px;
    gap: 5px;
  }

  .buttons:not(.open) .buttons__item:not(.open, .js-active) {
    display: none;
  }

  .buttons {
    flex-direction: column;
    gap: 1px;
  }

  .buttons__item.js-active {
    order: -1;
  }

  .buttons__item {
    width: 100%;
    padding: 0;
  }

  .js-active .buttons__link {
    position: relative;
    align-items: center;
  }

  .js-active .buttons__link:after {
    content: url("/public/images/arrow-dropdown.svg");
    position: absolute;
    right: 25px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
  }

  .buttons.open .js-active .buttons__link {
    background-color: var(--blue-2);
    transition: 0.3s;
  }

  .buttons.open .js-active .buttons__link:after {
    content: url("/public/images/arrow-dropdown.svg");
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .map__container {
    height: 220px;
  }

  .modal {
    padding: 0 10px;
  }

  .balloon {
    min-width: auto;
    overflow: visible;
  }
}

@media (max-width: 530px) {
  .maps {
    height: 320px;
  }

  .balloon {
    padding: 25px 0;
  }

  .balloon__texts {
    color: var(--black-2);
  }

  .ymaps-2-1-79-balloon__content {
    background-color: initial !important;
  }

  .balloon__title,
  .balloon__address {
    padding: 0 10px;
    border-left: 3px solid var(--blue-2);
  }

  .balloon__title {
    position: relative;
  }

  .balloon__title:before {
    content: '';
    width: 3px;
    height: 10px;
    position: absolute;
    top: 100%;
    left: -3px;
    background-color: var(--blue-2);
  }

  .balloon__image {
    width: 80px;
    height: 80px;
  }

  .show-530 {
    display: block;
  }

  .hide-530 {
    display: none;
  }

  .balloon__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }

  .ymaps-2-1-79-balloon__close+.ymaps-2-1-79-balloon__content {
    margin: 0 !important;
  }

  .address {
    width: auto;
    font-size: 14px;
    line-height: 18px;
    color: var(--black-2);
    justify-content: flex-start;
  }

  .ymaps-2-1-79-balloon__close-button {
    width: 30px;
  }

  .ymaps-2-1-79-balloon__close {
    right: 10px;
  }
}
