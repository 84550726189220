.redactor-styles {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 26px;
  gap: 20px;
  font-family: "Inter", "Arial", sans-serif;
  color: var(--black-2);
}

.redactor-styles blockquote {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.redactor-styles figcaption {
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.redactor-styles a {
  color: var(--orange);
  transition: 0.3s;
}

.redactor-styles img {
  max-width: 100%;
  height: auto;
}

.redactor-styles ul,
.redactor-styles ol {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.redactor-styles ul li {
  display: flex;
  gap: 15px;
  align-items: baseline;
}

.redactor-styles ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--blue-2);
  flex-shrink: 0;
  border-radius: 100%;
}

.redactor-styles ol {
  counter-reset: num;
  padding-inline-start: 25px;
}

.redactor-styles ol li {
  gap: 5px;
}

.redactor-styles ol li span {
  color: var(--blue-2);
  min-width: 25px;
}

.redactor-styles .text-center {
  text-align: center;
}

.redactor-styles .text-right {
  text-align: right;
}

.redactor-styles .text-justify {
  text-align: justify;
}

.redactor-styles table {
  width: auto;
}

.redactor-styles table td,
.redactor-styles table th {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 14px;
}

.redactor-styles h1,
.redactor-styles h2,
.redactor-styles h3,
.redactor-styles h4,
.redactor-styles h5,
.redactor-styles h6 {
  font-family: "Inter", "Arial", sans-serif;
}

.redactor-styles h1 {
  font-size: 28px;
  line-height: 34px;
}

.redactor-styles h2 {
  font-size: 26px;
  line-height: 30px;
}

.redactor-styles h3 {
  font-weight: 600;
}

.redactor-styles h4 {
  font-size: 22px;
  line-height: 26px;
}

.redactor-styles iframe {
  max-width: 100%;
  width: 100% !important;
  height: 665px !important;
}

.redactor-styles address, .redactor-styles blockquote, .redactor-styles dl, .redactor-styles figure, .redactor-styles hr, .redactor-styles ol, .redactor-styles p, .redactor-styles pre, .redactor-styles table, .redactor-styles ul {
  margin-bottom: 0;
}

@media (max-width: 1180px) {
  .redactor-styles iframe {
    height: calc((100vw - 165px)*9/16);
  }
}

@media (max-width: 1080px) {
  .redactor-styles iframe {
    height: calc((100vw - 45px)*9/16);
  }
}

@media (max-width: 980px) {
  .redactor-styles iframe {
    height: calc((100vw - 35px)*9/16);
  }

  .redactor-styles ul li {
    gap: 10px;
  }

  .redactor-styles ol {
    padding-inline-start: 20px;
  }
}

@media (max-width: 480px) {
  .redactor-styles {
    font-size: 16px;
    line-height: 24px;
  }

  .redactor-styles ul li {
    gap: 5px;
  }

  .redactor-styles ol {
    padding-inline-start: 15px;
  }
}
