.calendar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
}

.calendar__top {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}

.calendar__left {
  display: flex;
  align-items: center;
  gap: 30px;
}

.calendar__arrows {
  display: flex;
  align-items: center;
  gap: 20px;
}

.calendar__arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--blue-2);
  flex-shrink: 0;
  transition: 0.3s;
}

.calendar__arrow:hover {
  background-color: var(--blue-3);
  transition: 0.3s;
}

.calendar__arrow--right {
  transform: rotate(180deg);
}

.calendar__icon {
  fill: var(--white);
}

.calendar__date {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
}

.fc {
  font-family: "Inter", "Arial", sans-serif;
}

.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  border: none;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none;
}

.fc .fc-col-header-cell-cushion {
  font-size: 14px;
  line-height: 20px;
  color: var(--blue-2);
  font-weight: 400;
  padding: 15px 5px;
}

.fc .fc-daygrid-body {
  border: 1px solid rgba(var(--blue-2-opacity), 0.2);
  overflow: hidden;
  border-radius: 4px;
}

.fc .fc-daygrid-day-number {
  font-size: 14px;
  line-height: 16px;
  color: var(--black-2);
  font-weight: 500;
  padding: 10px;
}

.fc .fc-daygrid-day-frame {
  border: 1px solid rgba(var(--blue-2-opacity), 0.2);
  background-color: var(--light-gray);
  min-height: 160px;
  cursor: pointer;
}

.fc .fc-day-other .fc-daygrid-day-frame {
  background-color: var(--light-gray-1);
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin: 10px 5px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0;
  pointer-events: none;
  border: none;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  font-size: 12px;
  line-height: 14px;
  padding: 3px 5px;
}

.day__name {
  display: none;
}

.fc-day-today .day__date {
  width: 25px;
  height: 25px;
  margin: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-2);
  color: var(--white);
  border-radius: 4px;
}

.fc .fc-scrollgrid-section-sticky > * {
  z-index: 0;
}

@media (max-width: 1180px) {
  .calendar__arrows {
    gap: 15px;
  }

  .calendar__left {
    gap: 25px;
  }
}

@media (max-width: 980px) {
  .calendar__top {
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
  }

  .fc-scrollgrid-section-header {
    display: none;
  }

  .day {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .day__name {
    display: block;
    color: var(--blue-2);
    font-weight: 400;
  }

  .fc .fc-daygrid-body tbody {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .fc .fc-daygrid-body tr {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 100px;
  }

  .fc .fc-daygrid-body {
    border: none;
  }

  .fc .fc-daygrid-day-frame {
    border-radius: 4px;
    overflow: hidden;
  }
}

@media (max-width: 780px) {
  .calendar__date {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 680px) {

}

@media (max-width: 580px) {

}

@media (max-width: 480px) {
  .calendar__date {
    font-size: 22px;
    line-height: 26px;
  }

  .calendar__left {
    position: relative;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    gap: 0;
  }

  .calendar__arrow {
    position: absolute;
    left: 0;
  }

  .calendar__arrow--right {
    right: 0;
    left: auto;
  }
}
